body {
  color: #495057;
  background-color: #eaedf1;
}

a {
  color: #B80202;
}
a.link-fx::before {
  background-color: #B80202;
}
a:hover {
  color: #530101;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: #212529;
}

.content-heading {
  border-bottom-color: #dbe0e7;
}

hr {
  border-top-color: #dbe0e7;
}

.text-primary {
  color: #B80202 !important;
}

a.text-primary.link-fx::before {
  background-color: #B80202;
}
a.text-primary:hover, a.text-primary:focus {
  color: #530101 !important;
}

.text-primary-dark {
  color: #394263 !important;
}

a.text-primary-dark.link-fx::before {
  background-color: #394263;
}
a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #141722 !important;
}

.text-primary-darker {
  color: #282e45 !important;
}

a.text-primary-darker.link-fx::before {
  background-color: #282e45;
}
a.text-primary-darker:hover, a.text-primary-darker:focus {
  color: #030304 !important;
}

.text-primary-light {
  color: #fc0a0a !important;
}

a.text-primary-light.link-fx::before {
  background-color: #fc0a0a;
}
a.text-primary-light:hover, a.text-primary-light:focus {
  color: #9f0202 !important;
}

.text-primary-lighter {
  color: #fd4242 !important;
}

a.text-primary-lighter.link-fx::before {
  background-color: #fd4242;
}
a.text-primary-lighter:hover, a.text-primary-lighter:focus {
  color: #d60202 !important;
}

.text-body-bg {
  color: #eaedf1 !important;
}

a.text-body-bg.link-fx::before {
  background-color: #eaedf1;
}
a.text-body-bg:hover, a.text-body-bg:focus {
  color: #adb9c8 !important;
}

.text-body-bg-light {
  color: #f3f5f7 !important;
}

a.text-body-bg-light.link-fx::before {
  background-color: #f3f5f7;
}
a.text-body-bg-light:hover, a.text-body-bg-light:focus {
  color: #b6c0ce !important;
}

.text-body-bg-dark {
  color: #dbe0e7 !important;
}

a.text-body-bg-dark.link-fx::before {
  background-color: #dbe0e7;
}
a.text-body-bg-dark:hover, a.text-body-bg-dark:focus {
  color: #9eabbe !important;
}

.text-body-color {
  color: #495057 !important;
}

a.text-body-color.link-fx::before {
  background-color: #495057;
}
a.text-body-color:hover, a.text-body-color:focus {
  color: #1a1d20 !important;
}

.text-body-color-dark {
  color: #212529 !important;
}

a.text-body-color-dark.link-fx::before {
  background-color: #212529;
}
a.text-body-color-dark:hover, a.text-body-color-dark:focus {
  color: black !important;
}

.text-body-color-light {
  color: #dbe0e7 !important;
}

a.text-body-color-light.link-fx::before {
  background-color: #dbe0e7;
}
a.text-body-color-light:hover, a.text-body-color-light:focus {
  color: #9eabbe !important;
}

.text-dual {
  color: #394263 !important;
}

a.text-dual.link-fx::before {
  background-color: #394263;
}
a.text-dual:hover, a.text-dual:focus {
  color: #141722 !important;
}

.page-header-dark #page-header .text-dual,
.sidebar-dark #sidebar .text-dual {
  color: #dbe0e7 !important;
}
.page-header-dark #page-header a.text-dual.link-fx::before,
.sidebar-dark #sidebar a.text-dual.link-fx::before {
  background-color: #dbe0e7;
}
.page-header-dark #page-header a.text-dual:hover, .page-header-dark #page-header a.text-dual:focus,
.sidebar-dark #sidebar a.text-dual:hover,
.sidebar-dark #sidebar a.text-dual:focus {
  color: #9eabbe !important;
}

.bg-primary {
  background-color: #B80202 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #860101 !important;
}

.bg-primary-op {
  background-color: rgba(184, 2, 2, 0.75) !important;
}

a.bg-primary-op:hover, a.bg-primary-op:focus,
button.bg-primary-op:hover,
button.bg-primary-op:focus {
  background-color: rgba(134, 1, 1, 0.75) !important;
}

.bg-primary-dark {
  background-color: #394263 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #262c43 !important;
}

.bg-primary-dark-op {
  background-color: rgba(57, 66, 99, 0.8) !important;
}

a.bg-primary-dark-op:hover, a.bg-primary-dark-op:focus,
button.bg-primary-dark-op:hover,
button.bg-primary-dark-op:focus {
  background-color: rgba(38, 44, 67, 0.8) !important;
}

.bg-primary-darker {
  background-color: #282e45 !important;
}

a.bg-primary-darker:hover, a.bg-primary-darker:focus,
button.bg-primary-darker:hover,
button.bg-primary-darker:focus {
  background-color: #151825 !important;
}

.bg-primary-light {
  background-color: #fc0a0a !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #d10202 !important;
}

.bg-primary-lighter {
  background-color: #fd4242 !important;
}

a.bg-primary-lighter:hover, a.bg-primary-lighter:focus,
button.bg-primary-lighter:hover,
button.bg-primary-lighter:focus {
  background-color: #fc0f0f !important;
}

.bg-body {
  background-color: #eaedf1 !important;
}

a.bg-body:hover, a.bg-body:focus,
button.bg-body:hover,
button.bg-body:focus {
  background-color: #cbd3dd !important;
}

.bg-body-light {
  background-color: #f3f5f7 !important;
}

a.bg-body-light:hover, a.bg-body-light:focus,
button.bg-body-light:hover,
button.bg-body-light:focus {
  background-color: #d5dbe3 !important;
}

.bg-body-dark {
  background-color: #dbe0e7 !important;
}

a.bg-body-dark:hover, a.bg-body-dark:focus,
button.bg-body-dark:hover,
button.bg-body-dark:focus {
  background-color: #bcc6d2 !important;
}

.bg-header-light {
  background-color: #fff !important;
}

a.bg-header-light:hover, a.bg-header-light:focus,
button.bg-header-light:hover,
button.bg-header-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-header-dark {
  background-color: #B80202 !important;
}

a.bg-header-dark:hover, a.bg-header-dark:focus,
button.bg-header-dark:hover,
button.bg-header-dark:focus {
  background-color: #860101 !important;
}

.bg-sidebar-light {
  background-color: #fff !important;
}

a.bg-sidebar-light:hover, a.bg-sidebar-light:focus,
button.bg-sidebar-light:hover,
button.bg-sidebar-light:focus {
  background-color: #e6e6e6 !important;
}

.bg-sidebar-dark {
  background-color: #3b3946 !important;
}

a.bg-sidebar-dark:hover, a.bg-sidebar-dark:focus,
button.bg-sidebar-dark:hover,
button.bg-sidebar-dark:focus {
  background-color: #23222a !important;
}

.bg-gd-primary {
  background: #B80202 linear-gradient(135deg, #B80202 0%, #fc0a0a 100%) !important;
}

.btn-link {
  color: #B80202;
}
.btn-link:hover {
  color: #530101;
}

.btn-primary {
  color: #fff;
  background-color: #B80202;
  border-color: #B80202;
}
.btn-primary:hover {
  color: #fff;
  background-color: #920202;
  border-color: #860101;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #920202;
  border-color: #860101;
  box-shadow: 0 0 0 0.2rem rgba(195, 40, 40, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #B80202;
  border-color: #B80202;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #860101;
  border-color: #790101;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 40, 40, 0.5);
}

.btn-outline-primary {
  color: #B80202;
  border-color: #B80202;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #B80202;
  border-color: #B80202;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 2, 2, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #B80202;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #B80202;
  border-color: #B80202;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 2, 2, 0.5);
}

.btn-alt-primary {
  color: #394263;
  background-color: #fd2828;
  border-color: #fd2828;
}
.btn-alt-primary:hover {
  color: #394263;
  background-color: #fc0303;
  border-color: #fc0303;
}
.btn-alt-primary:focus, .btn-alt-primary.focus {
  color: #394263;
  background-color: #fc0303;
  border-color: #fc0303;
  box-shadow: 0 0 0 0.2rem rgba(229, 2, 2, 0.25);
}
.btn-alt-primary.disabled, .btn-alt-primary:disabled {
  color: #fff;
  background-color: #fd2828;
  border-color: #fd2828;
}
.btn-alt-primary:not(:disabled):not(.disabled):active, .btn-alt-primary:not(:disabled):not(.disabled).active, .show > .btn-alt-primary.dropdown-toggle {
  color: #1d2232;
  background-color: #e50202;
  border-color: #e50202;
}
.btn-alt-primary:not(:disabled):not(.disabled):active:focus, .btn-alt-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-alt-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 2, 2, 0.25);
}

.btn-alt-secondary {
  color: #495057;
  background-color: #eaedf1;
  border-color: #eaedf1;
}
.btn-alt-secondary:hover {
  color: #495057;
  background-color: #d3d9e2;
  border-color: #d3d9e2;
}
.btn-alt-secondary:focus, .btn-alt-secondary.focus {
  color: #495057;
  background-color: #d3d9e2;
  border-color: #d3d9e2;
  box-shadow: 0 0 0 0.2rem rgba(197, 206, 217, 0.25);
}
.btn-alt-secondary.disabled, .btn-alt-secondary:disabled {
  color: #212529;
  background-color: #eaedf1;
  border-color: #eaedf1;
}
.btn-alt-secondary:not(:disabled):not(.disabled):active, .btn-alt-secondary:not(:disabled):not(.disabled).active, .show > .btn-alt-secondary.dropdown-toggle {
  color: #262a2d;
  background-color: #c5ced9;
  border-color: #c5ced9;
}
.btn-alt-secondary:not(:disabled):not(.disabled):active:focus, .btn-alt-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-alt-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 206, 217, 0.25);
}

.btn-hero-primary {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.0625rem;
  font-weight: 700;
  padding: 0.625rem 1.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
  background-color: #B80202;
  border: none;
  box-shadow: 0 0.125rem 0.75rem rgba(108, 1, 1, 0.25);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, transform 0.12s ease-out;
}
.btn-hero-primary:hover {
  color: #fff;
  background-color: #ea0303;
  box-shadow: 0 0.375rem 0.75rem rgba(108, 1, 1, 0.4);
  transform: translateY(-1px);
}
.btn-hero-primary:focus, .btn-hero-primary.focus {
  color: #fff;
  background-color: #ea0303;
  box-shadow: 0 0.125rem 0.75rem rgba(108, 1, 1, 0.25);
}
.btn-hero-primary.disabled, .btn-hero-primary:disabled {
  color: #fff;
  background-color: #B80202;
  box-shadow: 0 0.125rem 0.75rem rgba(108, 1, 1, 0.25);
  transform: translateY(0);
}
.btn-hero-primary:not(:disabled):not(.disabled):active, .btn-hero-primary:not(:disabled):not(.disabled).active, .show > .btn-hero-primary.dropdown-toggle {
  color: #fff;
  background-color: #6c0101;
  box-shadow: 0 0.125rem 0.75rem rgba(108, 1, 1, 0.25);
  transform: translateY(0);
}
.btn-hero-primary:not(:disabled):not(.disabled):active:focus, .btn-hero-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-hero-primary.dropdown-toggle:focus {
  box-shadow: 0 0.125rem 0.75rem rgba(108, 1, 1, 0.25);
}

.btn-hero-lg {
  padding: 0.875rem 2.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-hero-sm {
  padding: 0.375rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-dual {
  color: #16181a;
  background-color: #f3f5f7;
  border-color: #f3f5f7;
}
.btn-dual:hover, .btn-dual:focus, .btn-dual.focus {
  color: #16181a;
  background-color: #cbd3dd;
  border-color: #cbd3dd;
  box-shadow: none;
}
.btn-dual.disabled, .btn-dual:disabled {
  background-color: transparent;
  border-color: transparent;
}
.btn-dual:active, .btn-dual.active {
  color: #16181a;
  background-color: #f3f5f7;
  border-color: #f3f5f7;
}
.btn-dual:not(:disabled):not(.disabled):active, .btn-dual:not(:disabled):not(.disabled).active, .show > .btn-dual.dropdown-toggle {
  color: #16181a;
  background-color: #cbd3dd;
  border-color: #cbd3dd;
}

.page-header-dark #page-header .btn-dual,
.sidebar-dark #sidebar .btn-dual {
  color: #fff;
  background-color: #cf0202;
  border-color: #cf0202;
}
.page-header-dark #page-header .btn-dual:hover, .page-header-dark #page-header .btn-dual:focus, .page-header-dark #page-header .btn-dual.focus,
.sidebar-dark #sidebar .btn-dual:hover,
.sidebar-dark #sidebar .btn-dual:focus,
.sidebar-dark #sidebar .btn-dual.focus {
  color: #fff;
  background-color: #9a0202;
  border-color: #9a0202;
  box-shadow: none;
}
.page-header-dark #page-header .btn-dual.disabled, .page-header-dark #page-header .btn-dual:disabled,
.sidebar-dark #sidebar .btn-dual.disabled,
.sidebar-dark #sidebar .btn-dual:disabled {
  background-color: transparent;
  border-color: transparent;
}
.page-header-dark #page-header .btn-dual:active, .page-header-dark #page-header .btn-dual.active,
.sidebar-dark #sidebar .btn-dual:active,
.sidebar-dark #sidebar .btn-dual.active {
  color: #fff;
  background-color: #cf0202;
  border-color: #cf0202;
}
.page-header-dark #page-header .btn-dual:not(:disabled):not(.disabled):active, .page-header-dark #page-header .btn-dual:not(:disabled):not(.disabled).active, .show > .page-header-dark #page-header .btn-dual.dropdown-toggle,
.sidebar-dark #sidebar .btn-dual:not(:disabled):not(.disabled):active,
.sidebar-dark #sidebar .btn-dual:not(:disabled):not(.disabled).active,
.show > .sidebar-dark #sidebar .btn-dual.dropdown-toggle {
  color: #fff;
  background-color: #9a0202;
  border-color: #9a0202;
}

.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual:hover, .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual:focus, .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual.focus {
  color: #fff;
  background-color: #9a0202;
  border-color: #9a0202;
  box-shadow: none;
}
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual.disabled, .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual:disabled {
  background-color: transparent;
  border-color: transparent;
}
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual:active, .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual.active {
  color: #fff;
  background-color: transparent;
  border-color: transparent;
}
.page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual:not(:disabled):not(.disabled):active, .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual:not(:disabled):not(.disabled).active, .show > .page-header-dark.page-header-glass:not(.page-header-scroll) #page-header .btn-dual.dropdown-toggle {
  color: #fff;
  background-color: #9a0202;
  border-color: #9a0202;
}

.alert-primary {
  color: #394263;
  background-color: #fd4242;
  border-color: #fd4242;
}
.alert-primary hr {
  border-top-color: #fd2828;
}
.alert-primary .alert-link {
  color: #262c43;
}

.badge-primary {
  color: #fff;
  background-color: #B80202;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #860101;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(184, 2, 2, 0.5);
}

.progress-bar {
  background-color: #B80202;
}

.nav-link {
  color: #495057;
}
.nav-link:hover, .nav-link:focus {
  color: #B80202;
}

.nav-pills .nav-link:hover, .nav-pills .nav-link:focus {
  background-color: #eaedf1;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: #B80202;
}

.nav-tabs {
  border-bottom-color: #dbe0e7;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #dbe0e7 #dbe0e7 #dbe0e7;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: #dbe0e7 #dbe0e7 #fff;
}

.nav-tabs-block {
  background-color: #f3f5f7;
}
.nav-tabs-block .nav-link {
  border-color: transparent;
}
.nav-tabs-block .nav-link:hover, .nav-tabs-block .nav-link:focus {
  color: #B80202;
  background-color: #eaedf1;
  border-color: transparent;
}
.nav-tabs-block .nav-link.active,
.nav-tabs-block .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: transparent;
}

.nav-tabs-alt {
  border-bottom-color: #dbe0e7;
}
.nav-tabs-alt .nav-link {
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-alt .nav-link:hover, .nav-tabs-alt .nav-link:focus {
  color: #B80202;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #B80202;
}
.nav-tabs-alt .nav-link.active,
.nav-tabs-alt .nav-item.show .nav-link {
  color: #495057;
  background-color: transparent;
  border-color: transparent;
  box-shadow: inset 0 -3px #B80202;
}

.nav-items a {
  border-bottom-color: #eaedf1;
}
.nav-items a:hover {
  background-color: #f3f5f7;
}
.nav-items a:active {
  background-color: #eaedf1;
}
.nav-items > li:last-child > a {
  border-bottom: none;
}

.page-item.active .page-link {
  background-color: #B80202;
  border-color: #B80202;
}

.page-link {
  color: #495057;
  background-color: #eaedf1;
  border-color: #eaedf1;
}
.page-link:hover {
  color: #495057;
  background-color: #bcc6d2;
  border-color: #bcc6d2;
}
.page-link:focus {
  background-color: #dbe0e7;
  border-color: #dbe0e7;
}

.list-group-item-action {
  color: #495057;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  color: #495057;
  background-color: #f3f5f7;
}
.list-group-item-action:active {
  color: #495057;
  background-color: #dbe0e7;
}

.list-group-item {
  border-color: #dbe0e7;
}
.list-group-item.active {
  color: #fff;
  background-color: #B80202;
  border-color: #B80202;
}

.popover {
  border-color: #dbe0e7;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^=top] .arrow::before {
  border-top-color: #dbe0e7;
}
.bs-popover-top .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::after {
  border-top-color: #fff;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^=right] .arrow::before {
  border-right-color: #dbe0e7;
}
.bs-popover-right .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::after {
  border-right-color: #fff;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: #dbe0e7;
}
.bs-popover-bottom .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::after {
  border-bottom-color: #fff;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^=left] .arrow::before {
  border-left-color: #dbe0e7;
}
.bs-popover-left .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::after {
  border-left-color: #fff;
}

.modal-header {
  border-bottom-color: #dbe0e7;
}

.modal-footer {
  border-top-color: #dbe0e7;
}

.dropdown-menu {
  border-color: #dbe0e7;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #eaedf1;
}

.dropdown-item {
  color: #495057;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #212529;
  background-color: #eaedf1;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  background-color: #B80202;
}

.dropdown-header {
  color: #212529;
}

.table th,
.table td {
  border-top-color: #dbe0e7;
}
.table thead th {
  border-bottom-color: #dbe0e7;
}
.table tbody + tbody {
  border-top-color: #dbe0e7;
}

.table-bordered,
.table-bordered th,
.table-bordered td {
  border-color: #dbe0e7;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #eff1f4;
}

.table-hover tbody tr:hover {
  background-color: #e5e9ee;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #e5e9ee;
}

.table-hover .table-active:hover {
  background-color: #d6dce4;
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #d6dce4;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fd4242;
}

.table-hover .table-primary:hover {
  background-color: #fd2828;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #fd2828;
}

.table .thead-dark th {
  background-color: #394263;
  border-color: #394263;
}
.table .thead-light th {
  background-color: #dbe0e7;
  border-color: #dbe0e7;
}

.form-control {
  color: #495057;
  background-color: #fff;
  border-color: #d2d8e1;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fd3d3d;
  box-shadow: 0 0 0 0.2rem rgba(184, 2, 2, 0.25);
}
.form-control:disabled, .form-control[readonly] {
  background-color: #F4F6F8;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-control-primary .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #B80202;
}
.custom-control-primary .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(184, 2, 2, 0.25);
}
.custom-control-primary .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #fd2323;
}
.custom-control-primary .custom-control-label::before {
  background-color: #dbe0e7;
}
.custom-control-primary.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #B80202;
}
.custom-control-primary.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #B80202;
}
.custom-control-primary.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #B80202;
}
.custom-control-primary.custom-block .custom-block-indicator {
  background-color: #B80202;
}
.custom-control-primary.custom-block .custom-control-input:checked ~ .custom-control-label {
  border-color: #B80202;
}
.custom-control-primary.custom-block .custom-control-input:focus ~ .custom-control-label {
  box-shadow: 0 0 0.75rem rgba(184, 2, 2, 0.25);
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #B80202;
  border-color: #B80202;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(184, 2, 2, 0.25);
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #fd4242;
}

.custom-select {
  border-color: #d2d8e1;
}
.custom-select:focus {
  border-color: #fd3d3d;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(253, 61, 61, 0.5);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #fd3d3d;
  box-shadow: 0 0 0 0.2rem rgba(184, 2, 2, 0.25);
}
.custom-file-input:focus ~ .custom-file-label::after {
  border-color: #fd3d3d;
}

.custom-file-label {
  color: #495057;
  background-color: #fff;
  border-color: #d2d8e1;
}
.custom-file-label::after {
  color: #495057;
  background-color: #dbe0e7;
  border-left-color: #d2d8e1;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #098E26;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(9, 142, 38, 0.9);
  border-radius: 0.5rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #098E26;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #098E26;
  box-shadow: 0 0 0 0.2rem rgba(9, 142, 38, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #098E26;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #098E26;
  box-shadow: 0 0 0 0.2rem rgba(9, 142, 38, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #098E26;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #098E26;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #098E26;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #0cbe33;
  background-color: #0cbe33;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(9, 142, 38, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #098E26;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #098E26;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #098E26;
  box-shadow: 0 0 0 0.2rem rgba(9, 142, 38, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #FF0000;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(255, 0, 0, 0.9);
  border-radius: 0.5rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #FF0000;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #FF0000;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #FF0000;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #FF0000;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #FF0000;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #FF0000;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #FF0000;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ff3333;
  background-color: #ff3333;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #FF0000;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #FF0000;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #FF0000;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25);
}

.form-control.form-control-alt {
  border-color: #eaedf1;
  background-color: #eaedf1;
}
.form-control.form-control-alt:focus {
  border-color: #dbe0e7;
  background-color: #dbe0e7;
  box-shadow: none;
}
.form-control.form-control-alt.is-valid {
  border-color: #68f587;
  background-color: #68f587;
}
.form-control.form-control-alt.is-valid:focus {
  border-color: #50f474;
  background-color: #50f474;
}
.form-control.form-control-alt.is-invalid {
  border-color: #fff0f0;
  background-color: #fff0f0;
}
.form-control.form-control-alt.is-invalid:focus {
  border-color: #ffe0e0;
  background-color: #ffe0e0;
}

.input-group-text {
  color: #495057;
  background-color: #eaedf1;
  border-color: #d2d8e1;
}

.input-group-text.input-group-text-alt {
  background-color: #dbe0e7;
  border-color: #dbe0e7;
}

.border {
  border-color: #dbe0e7 !important;
}

.border-top {
  border-top-color: #dbe0e7 !important;
}

.border-right {
  border-right-color: #dbe0e7 !important;
}

.border-bottom {
  border-bottom-color: #dbe0e7 !important;
}

.border-left {
  border-left-color: #dbe0e7 !important;
}

.border-primary-light {
  border-color: #fc0a0a !important;
}

.border-primary-lighter {
  border-color: #fd5656 !important;
}

.border-primary-dark {
  border-color: #860101 !important;
}

.border-primary-darker {
  border-color: #3a0101 !important;
}

.border-success-light {
  border-color: #21f14e !important;
}

.border-info-light {
  border-color: #47d1ff !important;
}

.border-warning-light {
  border-color: #fdd365 !important;
}

.border-danger-light {
  border-color: #ff7a7a !important;
}

.border-primary {
  border-color: #B80202 !important;
}

.border-secondary {
  border-color: #BFBFBF !important;
}

.border-success {
  border-color: #098E26 !important;
}

.border-info {
  border-color: #0099CC !important;
}

.border-warning {
  border-color: #E5A603 !important;
}

.border-danger {
  border-color: #FF0000 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #1E293B !important;
}

.border-primary {
  border-color: #B80202 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white-op {
  border-color: rgba(255, 255, 255, 0.1) !important;
}

.border-black-op {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

@media (min-width: 768px) {
  #page-header {
    background-color: #fff;
  }
}
@media (max-width: 767.98px) {
  #page-header {
    background-color: #B80202;
  }
}
#sidebar {
  background-color: #fff;
}

#side-overlay {
  background-color: #fff;
}

#page-container.page-header-dark #page-header {
  color: #c2cbd6;
  background-color: #B80202;
}
#page-container.page-header-glass #page-header {
  background-color: transparent;
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll #page-header {
  background-color: rgba(255, 255, 255, 0.9);
}
#page-container.page-header-glass.page-header-fixed.page-header-scroll.page-header-dark #page-header {
  background-color: rgba(184, 2, 2, 0.9);
}
#page-container.sidebar-dark #sidebar {
  color: #dbe0e7;
  background-color: #3b3946;
}

.block {
  box-shadow: 0 1px 3px rgba(211, 217, 226, 0.5), 0 1px 2px rgba(211, 217, 226, 0.5);
}

.block-header-default {
  background-color: #f3f5f7;
}

.block.block-bordered {
  border-color: #dbe0e7;
}
.block.block-themed > .block-header {
  background-color: #B80202;
}

.block.block-mode-loading::after {
  color: #B80202;
}

a.block {
  color: #495057;
}
a.block:hover {
  color: #495057;
}
a.block.block-link-pop:hover {
  box-shadow: 0 0.5rem 2rem #d2d8e1;
}
a.block.block-link-pop:active {
  box-shadow: 0 0.25rem 0.75rem #e7eaef;
}
a.block.block-link-shadow:hover {
  box-shadow: 0 0 2.25rem #d2d8e1;
}
a.block.block-link-shadow:active {
  box-shadow: 0 0 1.125rem #dee3e9;
}

.block.block-fx-shadow {
  box-shadow: 0 0 2.25rem #d2d8e1;
}
.block.block-fx-pop {
  box-shadow: 0 0.5rem 2rem #d2d8e1;
}

.btn-block-option {
  color: #B80202;
}
.btn-block-option:hover {
  color: #fc0a0a;
}
a.btn-block-option:focus,
.active > a.btn-block-option,
.show > button.btn-block-option {
  color: #fc0a0a;
}

.btn-block-option:active {
  color: #fd4242;
}

#page-loader {
  background-color: #B80202;
}

.nav-main-heading {
  color: #869099;
}

.nav-main-link {
  color: #555d65;
}
.nav-main-link .nav-main-link-icon {
  color: rgba(184, 2, 2, 0.7);
}
.nav-main-link:hover, .nav-main-link.active {
  color: #000;
}

.nav-main-submenu {
  background-color: #fe7979;
}
.nav-main-submenu .nav-main-link {
  color: #78838e;
}
.nav-main-submenu .nav-main-link:hover, .nav-main-submenu .nav-main-link.active {
  color: #383d42;
  background-color: transparent;
}

.nav-main-item.open > .nav-main-link-submenu {
  color: #000;
  background-color: #fd6565;
}

.nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    color: #000;
    background-color: #fd6565;
  }
}
.nav-main-dark .nav-main-heading,
.sidebar-dark #sidebar .nav-main-heading,
.page-header-dark #page-header .nav-main-heading {
  color: #848098;
}
.nav-main-dark .nav-main-link,
.sidebar-dark #sidebar .nav-main-link,
.page-header-dark #page-header .nav-main-link {
  color: #c8c7d1;
}
.nav-main-dark .nav-main-link > .nav-main-link-icon,
.sidebar-dark #sidebar .nav-main-link > .nav-main-link-icon,
.page-header-dark #page-header .nav-main-link > .nav-main-link-icon {
  color: #6a677e;
}
.nav-main-dark .nav-main-link:hover, .nav-main-dark .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-link.active,
.page-header-dark #page-header .nav-main-link:hover,
.page-header-dark #page-header .nav-main-link.active {
  color: #fff;
  background-color: #302f39;
}
.nav-main-dark .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-submenu,
.page-header-dark #page-header .nav-main-submenu {
  background-color: #34323e;
}
.nav-main-dark .nav-main-submenu .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-link {
  color: #adaaba;
}
.nav-main-dark .nav-main-submenu .nav-main-link:hover, .nav-main-dark .nav-main-submenu .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-link.active,
.page-header-dark #page-header .nav-main-submenu .nav-main-link:hover,
.page-header-dark #page-header .nav-main-submenu .nav-main-link.active {
  color: #fff;
  background-color: transparent;
}
.nav-main-dark .nav-main-item.open > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-link-submenu {
  color: #fff;
  background-color: #302f39;
}
.nav-main-dark .nav-main-item.open > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-item.open > .nav-main-submenu,
.page-header-dark #page-header .nav-main-item.open > .nav-main-submenu {
  background-color: #34323e;
}
.nav-main-dark .nav-main-submenu .nav-main-item.open .nav-main-link,
.sidebar-dark #sidebar .nav-main-submenu .nav-main-item.open .nav-main-link,
.page-header-dark #page-header .nav-main-submenu .nav-main-item.open .nav-main-link {
  background-color: transparent;
}

@media (min-width: 992px) {
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-link:hover, .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-link.active,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-link:hover,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-link.active,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-link:hover,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-link.active {
    background-color: #9f0202;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-link-submenu {
    background-color: #9f0202;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-item:hover > .nav-main-submenu {
    background-color: #9f0202;
  }
  .nav-main-dark.nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
.sidebar-dark #sidebar .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link,
.page-header-dark #page-header .nav-main-horizontal.nav-main-hover .nav-main-submenu .nav-main-item:hover .nav-main-link {
    background-color: transparent;
  }
}
.nav-items a {
  border-bottom-color: #eaedf1;
}
.nav-items a:hover {
  background-color: #f3f5f7;
}

.list-activity > li {
  border-bottom-color: #eaedf1;
}

.timeline-event-icon {
  box-shadow: 0 0.375rem 1.5rem #d2d8e1;
}

.ribbon-light .ribbon-box {
  color: #495057;
  background-color: #dbe0e7;
}
.ribbon-light.ribbon-bookmark .ribbon-box::before {
  border-color: #dbe0e7;
  border-left-color: transparent;
}
.ribbon-light.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #dbe0e7;
  border-right-color: transparent;
}

.ribbon-primary .ribbon-box {
  color: #fff;
  background-color: #B80202;
}
.ribbon-primary.ribbon-bookmark .ribbon-box::before {
  border-color: #B80202;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-bookmark.ribbon-left .ribbon-box::before {
  border-color: #B80202;
  border-right-color: transparent;
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #B80202;
  border-color: #B80202;
}

.cke_chrome,
.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
.ck.ck-toolbar {
  border-color: #dbe0e7 !important;
}

.cke_top,
.ck.ck-toolbar {
  border-bottom-color: #dbe0e7 !important;
  background: #f3f5f7 !important;
}

.ck.ck-toolbar .ck.ck-toolbar__separator {
  background: #dbe0e7 !important;
}

.cke_bottom {
  border-top-color: #dbe0e7 !important;
  background: #f3f5f7 !important;
}

.dropzone {
  background-color: #f3f5f7;
  border-color: #d2d8e1;
}
.dropzone .dz-message {
  color: #495057;
}
.dropzone:hover {
  background-color: #fff;
  border-color: #B80202;
}
.dropzone:hover .dz-message {
  color: #B80202;
}

.fc-theme-bootstrap .fc-h-event {
  background-color: #B80202;
  border: #B80202;
}
.fc-theme-bootstrap .fc-col-header-cell {
  background-color: #f3f5f7;
}

.irs.irs--round .irs-min,
.irs.irs--round .irs-max,
.irs.irs--round .irs-line,
.irs.irs--round .irs-grid-pol {
  background: #eaedf1;
}
.irs.irs--round .irs-handle {
  border-color: #B80202;
}
.irs.irs--round .irs-from:before,
.irs.irs--round .irs-to:before,
.irs.irs--round .irs-single:before {
  border-top-color: #B80202;
}
.irs.irs--round .irs-bar,
.irs.irs--round .irs-from,
.irs.irs--round .irs-to,
.irs.irs--round .irs-single {
  background: #B80202;
}

.select2-container--default .select2-selection--single {
  border-color: #d2d8e1;
}
.select2-container--default .select2-selection--multiple {
  border-color: #d2d8e1;
}
.select2-container--default.select2-container--focus .select2-selection--multiple, .select2-container--default.select2-container--focus .select2-selection--single, .select2-container--default.select2-container--open .select2-selection--multiple, .select2-container--default.select2-container--open .select2-selection--single {
  border-color: #fd3d3d;
  box-shadow: 0 0 0 0.2rem rgba(184, 2, 2, 0.25);
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #B80202;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: #d2d8e1;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #B80202;
}
.select2-container--default .select2-dropdown .select2-search__field:focus {
  border-color: #fd3d3d;
  box-shadow: 0 0 0 0.2rem rgba(184, 2, 2, 0.25);
}

.simplebar-scrollbar::before {
  background: rgba(21, 24, 37, 0.75);
}

.slick-slider .slick-prev::before,
.slick-slider .slick-next::before {
  color: #394263;
}

.editor-toolbar {
  border-color: #dbe0e7;
  background-color: #f3f5f7;
}

.CodeMirror {
  border-color: #dbe0e7;
}

.note-editor.note-frame {
  border-color: #dbe0e7;
}
.note-editor.note-frame .note-toolbar {
  background-color: #f3f5f7;
  border-bottom-color: #dbe0e7;
}
.note-editor.note-frame .note-statusbar {
  border-top-color: #dbe0e7;
  background-color: #f3f5f7;
}

.dd-handle {
  color: #495057;
  background: #f3f5f7;
  border-color: #dbe0e7;
}
.dd-handle:hover {
  color: #212529;
}

.dd-empty,
.dd-placeholder {
  border-color: #282e45;
  background: #fd4242;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  border-color: #B80202;
  background: #B80202;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #B80202;
}